import Swiper, { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

jQuery(document).ready(function($) {

	if ( typeof Swiper !== "undefined" ){
		new Swiper('.block-swiper', {
			modules: [Navigation],
			slidesPerView: "1",
			centeredSlides: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});

		new Swiper('.latest-news-swiper', {
			loop: true,
			modules: [Navigation],
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			spaceBetween: 15,
			slidesPerView: 1,
			breakpoints: {
				600: {
					slidesPerView: 3,
					spaceBetween: 20
				},
				1000: {
					slidesPerView: 4,
					spaceBetween: 25
				},
			},
		});

	}

});
