jQuery(document).ready(function($) {
    $('.tabs-js').each(function(){
        $(this).find('.tabs__panel').hide();
        $(this).find('.tabs__panel:first').show().addClass('tab-open');
        $(this).find('[data-tab]:first').addClass('active');
    });

    $('[data-tab]').click(function() {
        $(this).closest('.tabs-js').find('[data-tab]').removeClass('active');
        $(this).addClass('active');
        $(this).closest('.tabs-js').find('.tabs__panel').hide().removeClass('tab-open');

        var selectTab = $(this).attr('data-tab');
        $(selectTab).fadeIn().addClass('tab-open');
    });

    $('.tabs__select').on('change', function() {
        $('.tabs__panel').hide();

        var selectTab = $(this).val();

        $(selectTab).fadeIn();
    });
});
