jQuery(document).ready(function($) {
    $('li.menu-item-has-children > a').on('click',function(event){
        event.preventDefault()

        $(this).parent().find('ul').first().toggleClass('active');

        $(this).parent().siblings().find('ul').removeClass('active');

        //Hide menu when clicked outside
        $(this).parent().find('ul').mouseleave(function(){
        var thisUI = $(this);
        $('html').click(function(){
            thisUI.removeClass('active');
            $('html').unbind('click');
        });
        });
    });
});